import React, { useState } from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "../utility/NetworkUtils";
const RsvpForm = (props) => {
  const { rsvp_content, attendee } = props
  const [activitySelected, setActivitySelected] = useState(null);
  const [declinedReasoning, setDeclinedReasoning] = useState("");
  const [ error, setError ] = useState(null);
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const renderDeclinedContent = () => {
    const declineContentGid = "CTQ-1731538498-c660"
    return (
      <div>
        <div dangerouslySetInnerHTML={{__html:getContentFromTemplateByGid(rsvp_content, declineContentGid)}} />
        <input 
          className="form-control"
          type="text"
          value={ declinedReasoning }
          onChange={(e) => setDeclinedReasoning(e.target.value)}
        />
      </div>
    )
  }

  const activities = [
    {
      id: 0,
      name: "Session 1",
      content_gid: 'CTQ-1731538458-b3ed',
      rsvp_status: 'Accepted',
      color: "#EDF8EA",
      selected_color: "#cceac8",
      afterSubmitUrl: '/rsvp/confirmed'
    },
    {
      id: 1,
      name: "Session 2",
      content_gid: 'CTQ-1731538466-5732',
      rsvp_status: 'Accepted',
      color: "#E5F9F7",
      selected_color: "#b1ecd9",
      afterSubmitUrl: '/rsvp/confirmed'
    },
    {
      id: 2,
      name: "Will Not Attend",
      content_gid: 'CTQ-1731538476-8f0a',
      rsvp_status: 'Declined',
      color: "#E9F7FD",
      selected_color: "#9CD5ED",
      selectedContent: renderDeclinedContent,
      afterSubmitUrl: '/rsvp/declined'
    }
  ]
  
  const getContentFromTemplateByGid = (content, gid) => {
    return formatContent(content.data[gid] || "")
  }

  const formatContent = (content) => {
    const mergeTags = {
      '*|FNAME|*': attendee.name_first,
    }

    Object.keys(mergeTags).forEach((key) => {
      content = content.replaceAll(key, mergeTags[key])
    })
    return content
  }

  const renderButtons = () => {
    return (
      <div className="sg-rsvp-form-buttons">

        <button
          className="sg-button sg-rsvp-form-submit-button"
          disabled={isSubmitting}
          onClick={submitForm}
        >
          {isSubmitting ? <div className="dot-flashing"></div> : "Submit"}
        </button>
      </div>
    )
  }

  const renderSessions = () => {
    return activities.map(session => renderSession(session))
  }

  const renderSession = (session) => {
    const isSelected = activitySelected === session.id
    return (
      <div
        className={`sg-rsvp-form-session ${isSelected ? 'sg-rsvp-form-session-selected' : ''}`}
        key={session.id}
        style={{
          backgroundColor: isSelected ? session.selected_color : session.color
        }}
        onClick={() => setActivity(session)}
      >
        <div className="sg-rsvp-form-session-check"/>
        <div dangerouslySetInnerHTML={{__html:getContentFromTemplateByGid(rsvp_content, session.content_gid)}} />
        {isSelected && session.selectedContent ? session.selectedContent() : null}
      </div>
    );
  }

  const setActivity = (session) => {
    if(!isSubmitting) {
      setActivitySelected(session.id)
      setError(null)
      if(session.id != 2) {
        setDeclinedReasoning("")
      }
    }
  }

  const renderErrors = () => {
    if(error) {
      return (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )
    }
  }

  const submitForm = () => {
    if(activitySelected == null) {
      setError('Please select an option before continuing.')
      return
    }
    if(!isSubmitting) {
      setError(null)
      setIsSubmitting(true)
      const selectedActivity = activities.find(session => session.id === activitySelected)
      const body = {
        attendee: {
          metadata: {
            "rsvp-session-selected":  selectedActivity.name,
            "rsvp-declined-reasoning": declinedReasoning,
            "rsvp-status": selectedActivity.rsvp_status
          }
        }
      }

      fetchAPI(`/rsvp`, (json)=> {
        if(json.error) {
          setError(json.error)
          setIsSubmitting(false)
        } else {
          setError(null)
          setIsSubmitting(false)
          window.location.href = selectedActivity.afterSubmitUrl
        }
      }, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: body
      })
    }
  }
  const headerContentGid = 'CTQ-1731538448-c0da'
  return (
    <div className="sg-rsvp-form-container">
      <div className="sg-rsvp-form-header-content">
        <div dangerouslySetInnerHTML={{__html:getContentFromTemplateByGid(rsvp_content, headerContentGid)}} />
      </div>
      {renderSessions()}
      {renderErrors()}
      {renderButtons()}
    </div>
  )

}

export default RsvpForm